import { Component } from '@angular/core';

@Component({
    selector: 'under-review-banner',
    templateUrl: './under-review-banner.component.html',
    styleUrls: ['./under-review-banner.component.scss'],
})
export class UnderReviewBannerComponent {
    public isVisible = true;
}
