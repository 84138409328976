import { Component } from '@angular/core';

@Component({
    selector: 'rare-disease-day',
    templateUrl: './rare-disease-day.component.html',
    styleUrls: ['./rare-disease-day.component.scss'],
})
export class RareDiseaseDayComponent {
    REDIRECT_URL = 'https://reg.eventmobi.com/fdanihrdd2025';
    BANNER_IMAGE_URL = '/assets/FDA-NIH-RDD_2025_Event-Banner_600x150px_WHITE_FV.png';
    shouldShow = new Date() < new Date('2025-02-20');
}
